<template>
  <div class="password-input__field">
    <v-text-field
      outlined
      :type="passwordConfig.type"
      :class="{ required: isRequired }"
      :label="label"
      v-model="model"
      :id="id"
      ref="password"
      :hint="hint"
      :disabled="disabled"
      :persistent-hint="isHintPersistent"
      :rules="passwordRules"
      :hide-details="hideDetails"
      autocomplete="off"
      :name="_uid"
      :validate-on-blur="validateOnBlur"
      @update:error="showError"
    >
      <template #append>
        <tooltip v-if="showIcon" :name="passwordConfig.text">
          <template #default="{ on, attrs }">
            <v-icon
              :disabled="disabled"
              v-on="on"
              v-bind="attrs"
              @click="onPasswordIconClick"
              class="cursor-pointer"
              :class="{ 'error--text': hasError }"
            >
              {{ passwordConfig.icon }}
            </v-icon>
          </template>
        </tooltip>
        <slot name="append" />
      </template>
    </v-text-field>
    <slot></slot>
  </div>
</template>

<script>
import { isEmpty } from "@/utils.js";
import { required } from "@/validators";
import Tooltip from "../shared/Tooltip";

const EVENTS = ["update-password"];
const DEFAULT_LABEL = "Password";

/**
 * Password input field
 */
export default {
  name: "PasswordInputField",
  /**
   * -----------Components -------------
   */
  components: {
    Tooltip,
  },
  /**
   * ------------ Custom events --------------
   */
  emits: EVENTS,
  /**
   * -------------- Props -------------
   */
  props: {
    /**
     * @model
     */
    password: {
      validator: function (value) {
        const valType = typeof value;
        return ["string", "null", "number", "undefined"].includes(valType);
      },
      required: true,
    },
    /**
     * Is the input field required
     * @description Adds required icon on the input filed
     * and required validation on the password input field
     */
    isRequired: {
      type: Boolean,
      default: false,
    },
    showIcon: { type: Boolean, default: true },
    rules: {
      type: [Array],
      default: () => [],
    },
    /**
     * Id of text field
     */
    id: {
      type: String,
    },
    /**
     * Label of text field
     */
    label: {
      type: String,
      default: DEFAULT_LABEL,
    },
    hint: {
      type: String,
    },
    isHintPersistent: {
      type: Boolean,
    },
    disabled: { type: Boolean, default: false },
    /**
     * Will show error or success messages when required
     * @values auto
     */
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    validateOnBlur: { type: Boolean, default: false },
  },
  /**
   * -------------- Data Properties -------------
   */
  data() {
    return {
      showPassword: false,
      hasError: false,
    };
  },
  /**
   * -------------- Computed Properties -------------
   */
  computed: {
    /**
     * Calculates the password text field configuration
     * @type {Object}
     */
    passwordConfig() {
      const icon = "mdi-eye";
      const [show, hide] = ["text", "password"];

      return {
        icon: this.showPassword ? `${icon}-off` : icon,
        type: this.showPassword ? show : hide,
        text: this.showPassword ? "Hide" : "Show",
      };
    },
    /**
     * Password input field model
     * @type {String}
     * @emits update-password Event in the parent component and updates the password value
     */
    model: {
      /**
       * Gets password value from password prop
       */
      get() {
        return this.password;
      },
      set(val) {
        this.$emit(EVENTS[0], val);
      },
    },
    /**
     * Validation label name to be shown in validaion error message
     * @type {String}
     */
    validationLabel() {
      return this.label ?? DEFAULT_LABEL;
    },
    /**
     * Rules to validate the password text field
     * @type {Array}
     */
    passwordRules() {
      const rules =
        isEmpty(this.rules) && this.isRequired
          ? [required(this.validationLabel)]
          : [...this.rules];

      return rules;
    },
  },
  /**
   * -------------- Methods -------------
   */
  methods: {
    required,
    showError(error) {
      this.hasError = error;
    },
    /**
     * Password icon on click handler
     */
    onPasswordIconClick() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>
